import React from "react";
import { FaTimes } from "react-icons/fa";
import Modal from "../../../../shared/Modal";

const Stats = ({ isOpen, setIsOpen, name, level, rarity, edition, power, ambition, strength, talent }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      //   className='w-8/12'
    >
      <div
        className="w-full rounded-xl py-4 px-6"
        style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}
      >
        <div>
          <div className="mb-2 flex items-center justify-between">
            <div />
            <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-olive">
              Character Stats
            </h2>
            <FaTimes
              className="block text-2xl text-olive hover:cursor-pointer"
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </div>

          <div>
            <div className="mt-2">
              <Item text={"Name"} value={name} />
              <Item text={"Rarity"} value={rarity} />
              <Item text={"Level"} value={level} />
              <Item text={"Edition"} value={edition} />
              <Item text={"Hustling Power"} value={power} />
              <p className="mt-3 font-bold text-white">Attributes</p>
              <Item text={"Strength"} value={ambition} />
              <Item text={"Hustlers Ambition"} value={strength} />
              <Item text={"Talent"} value={talent} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const Item = ({ text, value }) => (
  <p className="text-white">
    <span className="text-white ">{text}:</span> {"  "} {value}
  </p>
);

export default Stats;
