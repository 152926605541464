import React, { useState } from "react";
import LeftContent from "./LeftContent";
import MiddleContent from "./MiddleContent";
import RightContent from "./RightContent";

const Content = ({ ual }) => {
  return (
    <div>
      <div className="flex flex-col flex-wrap items-center justify-between pb-6 md:flex-row md:items-start md:gap-y-5">
        <LeftContent ual={ual} />
        <MiddleContent ual={ual} />
        <RightContent ual={ual}/>
      </div>
    </div>
  );
};

export default Content;
