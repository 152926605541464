import React from "react";
import CornerContainer from "components/Hustle/Corner/Corner"
const Corner = ({ ual }) => {
  return (
    <div>
      <CornerContainer ual={ual} />
    </div>
  );
};

export default Corner;
