import React from "react";
import Content from "./Content";
import Top from "./Top";
import laboratory from "assets/images/laboratory.jpg";

const Clandestine = ({ ual }) => {
  return (
    <div className="py-10 md:min-h-screen" >
      <img src={laboratory} style={{position: 'fixed', top: 0, left: 0, width: "100%", height: "100vh", opacity: '0.3', zIndex: "0"}} />
      <div className="container mx-auto " style={{ backgroundSize:"cover", position:"relative"}}>
        <Top ual={ual} />
        <Content ual={ual} />
      </div>
    </div>
  );
};

export default Clandestine;
