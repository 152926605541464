import React, { useState, useEffect } from "react";
import icon1 from "assets/images/icons/work.png";
import ReUpForm from "./ReupForm";
import { useSelector } from "react-redux";

import { anchorGetBalance, anchorGetUnlockedLab, anchorGetGRank } from "BlockchainInteractionWax";
import "../../index.css"
import UnlockForm from "../forms/UnlockForm";

const Top = ({ ual }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUnlockOpen, setIsUnlockOpen] = useState(false);

  const [workResource, setWorkResource] = useState(0);
  const [isPageLocked, setIsPageLocked] = useState(false);
  const [gRank, setGRank] = useState(0);

  const store = useSelector((state) => state);

  const fetchData = async () => {
    if (ual.activeUser) {
      const values = await anchorGetBalance(ual);
      const unlocked = await anchorGetUnlockedLab(ual);
      if (unlocked == 1) {
        setIsPageLocked(false);
        await handleUnlock();
      }
      else {
        setIsPageLocked(true);
      }
      setWorkResource(parseFloat(values[3]).toFixed(3));
      const glevel = await anchorGetGRank(ual);
      setGRank(glevel)
    }
  };

  useEffect(() => {
    fetchData();
  }, [store, ual.activeUser]);

  return (
    <>
      <div className="flex flex-col items-center">
        <div className="img relative">
          {/* <img width="300" height="200" src={LabLogo} alt="Corner" /> */}
          {isPageLocked && gRank >= 1500 &&(
            <div className='buttonContainer my-5'  >
            <button className="w-40 px-4 py-1"           
              onClick={() => setIsUnlockOpen(true)}
            >
              Unlock Area
            </button>
            </div>
          )}
        </div>
        <div className={`flex flex-col items-center locked-area locked`}>
          <div className="flex flex-col items-center font-Montserrat">
            <p className="mb-1 text-md font-bold text-olive">Remaining Work</p>
            <div className="flex items-center gap-x-2">
              <div className="w-16">
                <img src={icon1} alt="" className="object- h-full w-full" />
              </div>
              <p className="mb-1 text-xl font-bold text-olive">
                {workResource}
              </p>
              <div className="w-16">
                <img src={icon1} alt="" className="object- h-full w-full" />
              </div>
            </div>
          </div>
          {isPageLocked ? 'locked' : 'unlocked' ? (
            <div className='buttonContainer '  >
              <button className="w-32" onClick={() => setIsOpen(true)}>Re-up</button>
            </div>
          ) : null}
        </div>
      </div>
      <ReUpForm setIsOpen={setIsOpen} isOpen={isOpen} ual={ual} />
      <UnlockForm setIsOpen={setIsUnlockOpen} isOpen={isUnlockOpen} ual={ual} />
    </>
  );
};

export const handleUnlock = () => {
  document.querySelectorAll(".locked-area").forEach((entry) => {
    entry.classList.remove("locked");
    entry.classList.add("unlocked");

    // Find the .lock-btn element within each .locked-area and remove it if it exists
    const lockBtn = entry.querySelector(".lock-btn");
    if (lockBtn && lockBtn.parentElement) {
      lockBtn.parentElement.removeChild(lockBtn);
    }
  });
};

export default Top;
