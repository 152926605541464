import { useState, useEffect } from 'react';
import { CheckIcon, ChevronUpDownIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}




export function SelectCategory({ children, items, labelName, onChange, value, error, isStatus, disabled, ...rest }) {
    const [query, setQuery] = useState('');
    // const [selectedItem, setSelectedItem] = useState(items[0]);
    const [selectedItem, setSelectedItem] = useState({});

    const filteredItem =
        query === ''
            ? items
            : items.filter(person => {
                return person.name.toLowerCase().includes(query.toLowerCase());
            });

    const onChangeMethod = item => {
        setSelectedItem(item);
        onChange(item);
    };

    useEffect(() => {
        if (value === 0) setSelectedItem({});
        items.map(item => {
            if (isStatus) {
                if (item.name === value) setSelectedItem(item);
            } else {
                if (item.id === value) setSelectedItem(item);
            }
        });
    }, [value]);

    return (
        <Combobox
            as='div'
            value={selectedItem}
            onChange={onChangeMethod}
            className={"pb-1 pr-2"}
        // disabled={disabled}
        >
            <Combobox.Label className='block text-sm font-medium text-lime-400'>{labelName}</Combobox.Label>
            <div className='relative mt-1 '>
                <Combobox.Input
                    className={classNames(
                        'w-full rounded-lg border border-[#2F2F2E]  bg-[#131312] py-3 pl-3 pr-10 shadow-sm focus:border-[#252525] focus:outline-none focus:ring-1 focus:ring-[#252525] sm:text-sm text-lime-400',
                        error ? 'border-red-400' : 'border-[#2F2F2E]'
                    )}
                    disabled={disabled}
                    onChange={event => setQuery(event.target.value)}
                    displayValue={person => person?.name}
                />
                <Combobox.Button className='absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none'>
                    <ChevronUpDownIcon className='w-5 h-5 text-lime-400' aria-hidden='true' />
                </Combobox.Button>

                {filteredItem.length > 0 && (
                    <Combobox.Options className='absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-[#131312] rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                        {filteredItem.map(item => (
                            <Combobox.Option
                                key={item.id}
                                value={item}
                                className={({ active }) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                        active ? 'bg-[#252525] text-lime-400' : 'text-lime-400'
                                    )
                                }
                            >
                                {({ active, selected }) => (
                                    <>
                                        <span className={classNames('block truncate', selected && 'font-semibold')}>{item.name}</span>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    active ? 'text-lime-400' : 'text-lime-400'
                                                )}
                                            >
                                                <CheckIcon className='w-5 h-5' aria-hidden='true' />
                                            </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
            {error ? <span className='text-sm text-lime-400'>error</span> : null}
        </Combobox>
    );
}
