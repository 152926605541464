import React from "react";
import Content from "./Content";
import Top from "./Top";
import traphouse from "assets/images/traphouse.jpg";

const Traphouse = ({ ual }) => {
  return (
    <div className="py-10 md:min-h-screen">

      <img src={traphouse} style={{ position: 'fixed', top: 0, left: 0, width: "100%", height: "100vh", opacity: '0.3', zIndex: "0" }} />

      <div className="container mx-auto " style={{ backgroundSize:"cover", position:"relative"}} >

        <Top ual={ual} style={{ zIndex: "100" }} />
        <Content ual={ual} style={{ zIndex: "100" }} />
        {/* <Bottom /> */}
      </div>



    </div>
  );
};

export default Traphouse;
