import React from "react";
// import Bottom from './Bottom';
import Content from "./Content";
import Top from "./Top";
import { UALProvider, withUAL } from "ual-reactjs-renderer";
import consignment from "assets/images/consignment.jpg";

const Consignment = ({ ual }) => {  
  return (
    <div className="py-10 md:min-h-screen">
      <img src={consignment} style={{ position: 'fixed', top: 0, left: 0, width: "100%", height: "100vh", opacity: '0.3', zIndex: "0" }} />
      <div className="container mx-auto " style={{ backgroundSize:"cover", position:"relative"}}>
        <Top ual={ual} />
        <Content ual={ual} />
        {/* <Bottom /> */}
      </div>
    </div>
  );
};

export default withUAL(Consignment);
